import React, { memo, useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Icon, { ICON_NAMES } from 'components/Icon/index';

const NotificationItem = memo((props) => {
  const { type, createdAt, isViewed, data, onRead, onDelete } = props;

  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const [open, setOpen] = useState(false);
  const [hasMoreBtn, setHasMoreBtn] = useState(false);
  const [isHiding, setIsHiding] = useState(false);
  const date = moment.utc(createdAt).format('DD.MM.YYYY');
  const time = moment.utc(createdAt).format('HH:mm');

  const contentRef = useRef();
  const contentWrapRef = useRef();

  const handleOpen = () => {
    const contentHeight = contentRef?.current?.clientHeight;

    if (open) {
      if (type === 'new_products') {
        contentWrapRef.current.style.height = '';
        contentWrapRef.current.style.maxHeight = '120px';
        setOpen(false);
        return;
      }
      contentWrapRef.current.style.height = '';
      contentWrapRef.current.style.maxHeight = '60px';
      setOpen(false);
    }
    if (!open) {
      contentWrapRef.current.style.height = contentHeight + 'px';
      contentWrapRef.current.style.maxHeight = contentHeight + 'px';
      setOpen(true);
    }
  };

  const deleteNotification = useCallback(() => {
    setIsHiding(true);
    setTimeout(() => onDelete(), 500);
  }, [onDelete]);

  useEffect(() => {
    const wrapHeight = contentWrapRef?.current?.clientHeight;
    const contentHeight = contentRef?.current?.clientHeight;

    if (contentHeight > wrapHeight) setHasMoreBtn(true);
    if (contentHeight <= wrapHeight) setHasMoreBtn(false);
  }, [width]);

  switch (type) {
    case 'debt': {
      const title = t('label.arrears');
      const description = t('label.debt_notification_message', {
        sum: data.debt,
        date: moment.utc(data.date).format('DD MMMM YYYY'),
      });

      return (
        <div
          className={classNames('notification-list__item item', {
            'item__info-status_hidden': isViewed,
            deleted: isHiding,
          })}
        >
          <div className="item__wrap">
            <div className="item__top-wrap">
              <div className="item__info-status">
                <span className="item__status" />
                <span className="item__date">
                  {date} <span className="item__time item__time_desktop">{time}</span>
                </span>
              </div>
              <div className="item__btns-wrap">
                {!isViewed && (
                  <button type="button" className="btn-change" onClick={onRead}>
                    <span className="item__btn-desktop">{t('action.read')}</span>
                    <span className="item__btn-adapt">{t('action.read_sm')}</span>
                  </button>
                )}
                <button type="button" className="btn-close" onClick={deleteNotification}>
                  {width >= 540 ? (
                    <span className="item__btn-desktop">{t('action.delete')}</span>
                  ) : (
                    <Icon name={ICON_NAMES.close} fill="#ff0000" />
                  )}
                </button>
              </div>
            </div>
            <div className="item__time item__time_adapt">{time}</div>
            <div className="item__title title">
              <h4>{title}</h4>
            </div>
          </div>
          <div className="item__desc-wrap">
            <div className="item__desc">{description}</div>
          </div>
        </div>
      );
    }
    case 'message': {
      return (
        <div
          className={classNames('notification-list__item item', {
            'item__info-status_hidden': isViewed,
            active: open,
          })}
        >
          <div className="item__wrap">
            <div className="item__top-wrap">
              <div className="item__info-status">
                <span className="item__status" />
                <span className="item__date">
                  {date} <span className="item__time item__time_desktop">{time}</span>
                </span>
              </div>
              <div className="item__btns-wrap">
                {!isViewed && (
                  <button type="button" className="btn-change" onClick={onRead}>
                    <span className="item__btn-desktop">{t('action.read')}</span>
                    <span className="item__btn-adapt">{t('action.read_sm')}</span>
                  </button>
                )}
                <button type="button" className="btn-close" onClick={deleteNotification}>
                  {width >= 540 ? (
                    <span className="item__btn-desktop">{t('action.delete')}</span>
                  ) : (
                    <Icon name={ICON_NAMES.close} fill="#ff0000" />
                  )}
                </button>
              </div>
            </div>
            <div className="item__time item__time_adapt">{time}</div>
            <div className="item__title title">
              <h4>{data.title}</h4>
            </div>
          </div>
          <div className="item__desc-wrap" ref={contentWrapRef}>
            <div className="item__desc" ref={contentRef}>
              {data.message}
            </div>
          </div>

          {hasMoreBtn && (
            <button type="button" className="btn-review" onClick={handleOpen}>
              {open ? t('action.hide') : t('action.in_more_detail')}
            </button>
          )}
        </div>
      );
    }
    case 'new_products': {
      return (
        <div
          className={classNames('notification-list__item item item_products', {
            'item__info-status_hidden': isViewed,
            active: open,
          })}
        >
          <div className="item__wrap">
            <div className="item__top-wrap">
              <div className="item__info-status">
                <span className="item__status" />
                <span className="item__date">
                  {date} <span className="item__time item__time_desktop">{time}</span>
                </span>
              </div>
              <div className="item__btns-wrap">
                {!isViewed && (
                  <button type="button" className="btn-change" onClick={onRead}>
                    <span className="item__btn-desktop">{t('action.read')}</span>
                    <span className="item__btn-adapt">{t('action.read_sm')}</span>
                  </button>
                )}
                <button type="button" className="btn-close" onClick={deleteNotification}>
                  {width >= 540 ? (
                    <span className="item__btn-desktop">{t('action.delete')}</span>
                  ) : (
                    <Icon name={ICON_NAMES.close} fill="#ff0000" />
                  )}
                </button>
              </div>
            </div>
            <div className="item__time item__time_adapt">{time}</div>
            <div className="item__title title">
              <h4>{t('info.your_goods_are_available')}</h4>
            </div>
          </div>
          <div className="item__desc-wrap" ref={contentWrapRef}>
            <div className="item__desc" ref={contentRef}>
              <div className="titles-row">
                <div className="col-title vendor-code-title">
                  <div className="label-wrap">
                    <span className="titles-row__title">{t('products.article')}</span>
                  </div>
                </div>
                <div className="col-title brand-title">
                  <div className="label-wrap">
                    <span className="titles-row__title">{t('products.brand')}</span>
                  </div>
                </div>
                <div className="col-title product-name-title">
                  <div className="label-wrap">
                    <span className="titles-row__title">{t('products.nomination')}</span>
                  </div>
                </div>
              </div>

              {data.products.map((item) => (
                <div className="cnt-row" key={item.item_id}>
                  <div className="column vendor-code">
                    <span className="title-mob">{t('products.article')}</span>
                    <Link to={`/product-details/${item.item_id}`} className="vendor-code__v-code link link_col-blue">
                      {item.article}
                    </Link>
                  </div>
                  <div className="column brand">
                    <span className="title-mob">{t('products.brand')}</span>
                    <p className="brand__text">{item.manufacturer}</p>
                  </div>
                  <div className="column product-name">
                    <span className="title-mob">{t('products.nomination')}</span>
                    <Link to={`product-details/${item.item_id}`} className="product-name__text">
                      {item.name}
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {hasMoreBtn && (
            <button type="button" className="btn-review" onClick={handleOpen}>
              {open ? t('action.hide') : t('action.in_more_detail')}
            </button>
          )}
        </div>
      );
    }
    default:
      return null;
  }
});

export default NotificationItem;
