import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ProductItem from './ProductItem';
import { tecdocDescriptionToText } from 'utils/tecdocDescriptionToText';
import { CommonDataContext } from 'HOC/withCommonData/withCommonData';
import { formatPrice } from 'utils/formatPrice';
import moment from 'moment';

const PromotionProductsGroup = (props) => {
  const {
    products,
    dealInfo,
    queryKey,
    selectedGiftId,
    pageType,
    tecdocActive,
    activeProductId,
    registration_deadline,
    handleActiveProduct,
    handleSelectProduct,
  } = props;

  const { productViewType } = useContext(CommonDataContext);

  const { t } = useTranslation();
  return (
    <div className="promo-products__group">
      <div className="promo-products__header">
        <h3>{dealInfo.title}</h3>
        <div className="promo-products__info">
          <p>
            {t('label.turnover_previous_period')}{' '}
            <span>
              {formatPrice(dealInfo.turnover)} {t('label.currency')}
            </span>
          </p>
          <p>
            {t('label.to_receive_gift')}{' '}
            <span>
              {formatPrice(dealInfo.tgift)} {t('label.currency')}
            </span>
          </p>
        </div>
      </div>
      <div className="catalog-wrap">
        {products &&
          products.map(({ description, ...product }) => {
            const normalizedDescription = Array.isArray(description) ? tecdocDescriptionToText(description) : description;
            const key = product.item_id ? product.item_id + productViewType : product.mfrId + product.article + productViewType;
            const isSelected = product.item_id === selectedGiftId;

            const isExpired = registration_deadline ? moment().unix() > moment(registration_deadline, 'YYYY-MM-DD hh:mm:ss').unix() : false;

            const isDisable = (Boolean(selectedGiftId) || isExpired) && !isSelected;

            return (
              <ProductItem
                key={key}
                queryKey={queryKey}
                autoliderID={product.item_id}
                productViewType={productViewType}
                name={product.name}
                article={product.article}
                mfrId={product.mfrId}
                manufacturer={product.manufacturer}
                description={normalizedDescription}
                isFavorite={product.is_favorite}
                pageType={pageType}
                images={product.images}
                isMultiImages={product.isMultiImages}
                logo={product.logo}
                warehouses={product.warehouses}
                isTecDoc={product.td}
                tecdocActive={tecdocActive}
                isFlipped={activeProductId === product.item_id}
                disable={isDisable}
                selected={isSelected}
                handleSelectProduct={handleSelectProduct}
                handleActiveProduct={handleActiveProduct}
              />
            );
          })}
      </div>
    </div>
  );
};

export default PromotionProductsGroup;
