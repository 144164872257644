export function getCategories(categories = []) {
  const toolsCategories = [];
  const restCategories = [];

  categories.forEach((cat) => {
    if (cat.tools_subcategories) {
      toolsCategories.push(cat);
    } else {
      restCategories.push(cat);
    }
  });

  return { toolsCategories, restCategories };
}
