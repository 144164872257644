const ICON_NAMES = {
  history: 'HISTORY',
  logOut: 'LOG_OUT',
  logo: 'LOGO',
  search: 'SEARCH',
  user: 'USER',
  dotsSquare: 'DOTS_SQUARE',
  info: 'INFO',
  close: 'CLOSE',
  selected: 'SELECTED',
  favoritesUnchecked: 'FAVORITES_UNCHECKED',
  favoritesChecked: 'FAVORITES_CHECKED',
  goBack: 'GO_BACK',
  goForward: 'GO_FORWARD',
  filterArrow: 'FILTER_ARROW',
  listArrowRight: 'LIST_ARROW_RIGHT',
  warning: 'WARNING',
  success: 'SUCCESS',
  list: 'LIST',
  card: 'CARD',
  liquid: 'LIQUID',
  car_battery: 'CAR_BATTERY',
  car_lamp: 'CAR_LAMP',
  auto_search: 'AUTO_SEARCH',
  motoSearch: 'MOTO_SEARCH',
  basket: 'BASKET',
  sales: 'SALES',
  news: 'NEWS',
  favorites: 'FAVORITES',
  manager: 'MANAGER',
  balance: 'BALANCE',
  order_history: 'ORDER_HISTORY',
  message: 'MESSAGE',
  arrows: 'ARROWS',
  arrows_asc: 'ARROWS_ASC',
  arrows_desc: 'ARROWS_DESC',
  copy: 'COPY',
  paginationArrowLeft: 'PAGINATION_ARROW_LEFT',
  paginationArrowRight: 'PAGINATION_ARROW_RIGHT',
  paginationArrowStart: 'PAGINATION_ARROW_START',
  paginationArrowEnd: 'PAGINATION_ARROW_END',
  deleteFromBasket: 'DELETE_FROM_BASKET',
  watched: 'WATCHED',
  clip: 'CLIP',
  bosch: 'BOSCH',
  tools: 'TOOLS',
  loyalty_coins: 'LOYALTY_COINS',
  cash: 'CASH',
  cashless: 'CASHLESS',
};

export default ICON_NAMES;
