import React, { useState, useRef, useCallback, memo } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Search from '../Search/Search';
import Icon, { ICON_NAMES } from 'components/Icon/index';
import Select from 'components/Select/Select';

import useWindowDimensions from 'hooks/useWindowDimensions';
import { useUpdateSettings } from 'hooks/api/useSettings';
import useBodyScrollLock from 'hooks/useBodyScrollLock';
import { useDisclosure } from 'hooks/useDisclosure';
import { languageOptions } from 'config/constants/selectOptions';
import { version } from 'config/packageJSON';
import { warehouseToLable } from 'utils/deliveryDataUtils';
import { getCatalogueNavigationUrl } from 'utils/getCatalogueNavigationUrl';
import { getCategories } from 'utils/getCategories';

const Menu = memo((props) => {
  const { catalogueTypes, user, historyData, isSearchByNameValid, isSearching, onSearch } = props;

  const { t } = useTranslation();
  const windowDimension = useWindowDimensions();

  const warehouses = user?.warehouses?.filter((w) => w.value !== 'expr' && w.value !== 'income') ?? [];

  const mainMenu = useDisclosure(false);
  const toolsList = useDisclosure(true);
  const catalogueList = useDisclosure(true);
  const warehousesList = useDisclosure(true);

  const [activeSearchSmallScreen, setActiveSearchSmallScreen] = useState(false);
  const openSearchSmallScreen = () => setActiveSearchSmallScreen(true);
  const closeSearchSmallScreen = () => setActiveSearchSmallScreen(false);

  const onCatalogueChange = useCallback(
    (event) => {
      event.stopPropagation();
      mainMenu.close();
    },
    [mainMenu.close]
  );

  const menuRef = useRef();
  useBodyScrollLock(menuRef, mainMenu.isOpen);

  const { mutate } = useUpdateSettings();
  const onMutate = (data) => mutate({ locale: data.value });

  const { toolsCategories, restCategories } = getCategories(catalogueTypes);

  return (
    <div className="menu-wrap">
      {windowDimension.width <= 1250 && (
        <Search
          smallScreen
          historyData={historyData}
          isActive={activeSearchSmallScreen}
          isSearchByNameValid={isSearchByNameValid}
          isSearching={isSearching}
          onClose={closeSearchSmallScreen}
          onSearch={onSearch}
        />
      )}

      <Link to="/" className="logo">
        <Icon name={ICON_NAMES.logo} />
      </Link>

      <div className={classNames('burger-wrap', { 'burger-wrap_hide': mainMenu.isOpen })} onClick={mainMenu.toggle}>
        <strong>{t('label.header_menu')}</strong>
        <div className={classNames('menu-wrap__btn-burger btn-burger', { active: mainMenu.isOpen })}>
          <div className="btn-burger__wrap">
            <div className="btn-burger__line btn-burger__line-top" />
            <div className="btn-burger__line btn-burger__line-middle" />
            <div className="btn-burger__line btn-burger__line-bottom" />
          </div>
        </div>
      </div>

      {windowDimension.width < 1251 && (
        <button type="button" className="menu-wrap__search btn-square btn-square_bg-col-astr" onClick={openSearchSmallScreen}>
          <Icon name={ICON_NAMES.search} />
        </button>
      )}

      {mainMenu.isOpen && (
        <>
          {windowDimension.width > 320 && <div className="overlay-backdrop" onClick={mainMenu.close} />}
          <nav className="nav-wrap" ref={menuRef}>
            <div className="nav-wrap__wrap">
              <Select
                options={languageOptions()}
                value={languageOptions().find((i) => i.value === user?.settings?.locale)}
                onSelect={onMutate}
                containerStyles={{
                  width: 120,
                  position: 'absolute',
                  height: '60px',
                  top: '20px',
                  left: '40px',
                }}
              />

              <ul className="list">
                <li>
                  <NavLink to="/" exact>
                    {t('label.header_menu_main')}
                  </NavLink>
                </li>

                <li className={classNames('drop-list', { active: catalogueList.isOpen })} onClick={catalogueList.toggle}>
                  <div className="drop-list__wrap">
                    <p>{t('label.catalogue')}</p>
                  </div>
                  <ul>
                    <li
                      className={classNames('drop-list', { active: toolsList.isOpen })}
                      onClick={(e) => {
                        e.stopPropagation();
                        toolsList.toggle();
                      }}
                    >
                      <div className="drop-list__wrap">
                        <p>{t('label.tools_catalogue')}</p>
                      </div>

                      <ul>
                        {toolsCategories.map((catalogue) => (
                          <li key={catalogue.name} onClick={onCatalogueChange}>
                            <NavLink to={getCatalogueNavigationUrl(catalogue)}>{catalogue.label}</NavLink>
                          </li>
                        ))}
                      </ul>
                    </li>

                    {catalogueList.isOpen &&
                      restCategories.map((catalogue) => (
                        <li key={catalogue.name} onClick={onCatalogueChange}>
                          <NavLink to={getCatalogueNavigationUrl(catalogue)}>{catalogue.label}</NavLink>
                        </li>
                      ))}
                  </ul>
                </li>

                <li>
                  <NavLink to="/basket" exact>
                    {t('label.header_menu_basket')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/sales" exact>
                    {t('label.header_menu_sales')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/loyalty-programs" exact>
                    {t('label.loyalty_program')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/news" exact>
                    {t('label.header_menu_news')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/favorites" exact>
                    {t('label.header_menu_favorites')}
                  </NavLink>
                </li>
                <li>
                  <a href="https://al1.ua/#brands" target="_blank" rel="noreferrer">
                    {t('label.header_menu_assortment')}
                  </a>
                </li>
                <li>
                  <NavLink to="/debts" exact>
                    {t('label.header_menu_balance')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/order-history" exact>
                    {t('label.header_menu_order_history')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/notification" exact>
                    {t('label.header_menu_notifications')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/downloads" exact>
                    {t('page_title.downloads')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/payment-details" exact>
                    {t('page_title.payment_details')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/report" exact>
                    {t('page_title.report')}
                  </NavLink>
                </li>

                <li className={classNames('drop-list', { active: warehousesList.isOpen })} onClick={warehousesList.toggle}>
                  <div className="drop-list__wrap">
                    <p>{t('label.about_us')}</p>
                  </div>
                  <ul>
                    {warehousesList.isOpen &&
                      warehouses.map((warehouse, index) => (
                        <NavLink
                          key={index}
                          to={`/about-us/${warehouse.value}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            mainMenu.close();
                          }}
                        >
                          {warehouseToLable(warehouse.value)}
                        </NavLink>
                      ))}
                  </ul>
                </li>
              </ul>
              <div className="copyright">
                <span>{t('label.header_menu_brand')}</span>
                <span>{version()}</span>
              </div>
            </div>
          </nav>
        </>
      )}
    </div>
  );
});

export default Menu;
